@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../../../global.module";

.headerWrapper {
  width: 914px;
  .updateHeader {
    min-width: 100%;
    padding: map-get($map: $scale, $key: 16);
    width: 100%;
    display: none;
    justify-content: space-between;
    &.showUpdate {
      border-radius: map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) 0 0;
      display: flex;
    }
    .updateHeaderBox {
      display: flex;
      align-items: center;
      gap: map-get($map: $scale, $key: 16);
    }
  }
  .shopDetails {
    width: 100%;
    padding: map-get($map: $scale, $key: 16);
    &.showUpdate {
      border-radius: 0 0 map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16);
    }
  }
  :local(.whiteText) {
    @extend .sub-hero-text;
    color: $white;
    span {
      text-decoration: underline;
    }
    &:focus,
    :hover {
      color: $white !important;
    }
    &:local(.wipStatus) {
      width: 100%;
      max-width: 196px;
      @extend .textEllipsis;
    }
    &:local(.wipBillingMilestone) {
      width: 100%;
      max-width: 266px;
      @extend .textEllipsis;
    }
  }
  :local(.updateBtn) {
    width: 140px;
  }
  :local(.detailContainer) {
    @extend .dashedBorder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: map-get($map: $scale, $key: 16);
    // div:first-child {
    //   width: 140px;
    // }
  }
  :local(.shopContainer) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: map-get($map: $scale, $key: 16);
    :local(.shopDetails) {
      justify-content: space-between;
      width: 598px;
      height: 138px;
      display: flex;
      flex-direction: column;

      :local(.propertyName) {
        @extend .d-flex;
        flex-direction: column;
        :local(.name) {
          @extend .sub-hero-text;
        }
        :local(.address) {
          @extend .sub-hero-text-regular-medium;
        }
      }
      :global(.sub-hero-text-regular) {
        border-bottom: none;
      }
    }
  }
}
:local(.step0Modal) {
  :global(.ant-modal-content) {
    padding: 0;
    padding-top: 4px;
  }
}
