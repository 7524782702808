@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.wipWrapper {
  min-width: auto !important;
  margin: auto !important;
  background: none !important;
  .breadCrumb {
    margin: map-get($map: $scale, $key: 12);
    :local(.breadCrumbItem) {
      font-weight: 500;
      color: black;
    }
    :local(.breadCrumbItem:hover) {
      cursor: pointer;
    }
  }
  :global(.ant-form-item-explain-error) {
    min-width: 200px !important;
  }
}

:local(.reqOuterContainer) {
  background-color: $white;
  padding: 20px;
  border-radius: 0 0 16px 16px;
  margin-bottom: 20px !important;
}

:local(.formTitle) {
  @extend .hero-text;
  padding: 20px;
  border-bottom: 1px solid map-get($map: $grey-color, $key: grey-400);
  background-color: $white;
  border-radius: 16px 16px 0 0;
}

:local(.reqInnerContainer) {
  display: flex;
  justify-content: start !important;
  & > div {
    width: 240px !important;
    margin-right: 90px;
    &:nth-child(3) {
      margin-right: 0;
    }
    :global(.custom-input-box) {
      max-width: 240px;
      min-width: 240px;
    }
  }
  &:local(.thirdChildNoMargin) {
    & > div {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  &:local(.hideLastNode) {
    & > div {
      &:last-child {
        display: none;
      }
    }
  }
}
:local(.commercialOuterConatiner) {
  align-items: center;
}
:local(.fullCommercialContainer) {
  & > div {
    width: fit-content !important;
    &:first-child {
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
}
:local(.progressContainer) {
  &::after,
  &::before {
    content: "";
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: transparent;
    border-left: 1px dashed map-get($map: $primary-color, $key: primary-400);
    display: inherit;
    height: 32px;
    margin-left: 11px;
    position: relative;
  }
  :local(.progressBox) {
    height: 19px;
    width: 20px;
    border-radius: 14px;
    border: 1px solid map-get($map: $primary-color, $key: primary-400);
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($map: $primary-color, $key: primary-400);
    font-size: 12px;
    padding-top: 1px;
  }
}
:local(.emptyBoxContainer) {
  &::after {
    content: "";
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: transparent;
    border-left: 1px dashed map-get($map: $primary-color, $key: primary-400);
    display: inherit;
    height: 36px;
    margin-left: 11px;
    position: relative;
  }
  :local(.emptyBox) {
    height: 22px;
    width: 22px;
    border-radius: 14px;
    background-color: map-get($map: $primary-color, $key: primary-400);
    display: inline-block;
  }
}
:local(.commercialInnerContainer) {
  display: flex;
  justify-content: space-between;
  width: 844px;
  max-width: 844px;
  & > div {
    width: 240px !important;
    :global(.custom-input-box) {
      max-width: 240px;
      min-width: 240px;
    }
    &:nth-child(4) {
      width: 24px !important;
      position: relative;
      right: 42px;
      margin-bottom: 22px;
    }
  }
  &:local(.brokerageContainer) {
    width: 884px;
    max-width: 884px;
    & > div {
      width: 150px !important;
      &:nth-child(4) {
        width: 0px !important;
      }
    }
  }
}
:local(.staticDataBox) {
  justify-content: flex-start !important;
  padding-top: 32px;
  max-height: 68px;
  & > div {
    &:first-child {
      width: 31px !important;
    }
    &:nth-child(2) {
      max-width: 844px !important;
    }
    &:nth-child(3) {
      width: 30px !important;
      position: relative;
      margin-bottom: 22px;
      margin-left: -44px;
    }
  }
}
:local(.formWrapper) {
  max-width: 946px;
  // width: 946px;
  &:local(.modalStyles) {
    :global(.formTitle) {
      padding-top: map-get($map: $scale, $key: 4);
    }
    :local(.inModalContainer) {
      max-height: 450px;
      overflow-y: scroll;
      padding-right: map-get($map: $scale, $key: 12);
    }
    :local(.inModalContainerCommercial) {
      max-height: 350px;
      overflow-y: scroll;
      padding-right: map-get($map: $scale, $key: 12);
      width: calc(100%);
      position: relative;
      padding-top: -(map-get($map: $scale, $key: 20));
    }
    :local(.fyBrokerageHeading) {
      padding-bottom: map-get($map: $scale, $key: 16);
    }
    :local(.reqOuterContainer) {
      padding-right: 0px;
    }
  }
}
:local(.submitButtonCtn) {
  padding-top: map-get($map: $scale, $key: 12);
  button {
    width: 200px !important;
  }
  :nth-child(2) {
    margin-left: map-get($map: $scale, $key: 40);
  }
}
:local(.rentBox) {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
:local(.fyBrokerageBox) {
  margin: 20px 0 16px;
  :local(.rentBox) {
    margin-bottom: 0;
  }
}
:local(.infoStripWrapper) {
  width: 946px;
}
:local(.stepsWrapper) {
  :global(.ant-steps-item):nth-child(2) {
    :global(.ant-steps-item-title) {
      width: 164px;
      position: relative;
      left: -(map-get($map: $scale, $key: 32));
    }
  }
  :global(.ant-steps-item):nth-child(3) {
    :global(.ant-steps-item-title) {
      width: 140px;
      position: relative;
      left: -(map-get($map: $scale, $key: 12));
    }
  }
}
:local(.btnWidth) {
  &:global(.ant-btn-primary) {
    width: 300px !important;
  }
}

:local(.revenueAllocationContainer) {
  & > div {
    &:nth-child(4n + 3) {
      margin-right: 0;
      :global(.custom-input-box) {
        border-bottom: none !important;
        :global(.ant-input-disabled) {
          @extend .text-regular;
          padding-top: map-get($map: $scale, $key: 16);
        }
      }
    }
  }
  & > div {
    &:nth-child(4n) {
      width: 30px !important;
      margin-right: 0px !important;
      position: relative;
      margin-left: -30px;
      margin-bottom: map-get($map: $scale, $key: 20);
    }
  }
}
