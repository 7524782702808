@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.overViewPageLayout {
  display: flex;
  flex-direction: column;
  gap: map-get($map: $scale, $key: 12);
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
  .recentUpdate {
    display: flex;
    flex-direction: column;
    padding: map-get($map: $scale, $key: 16);
    width: 100%;
    background-color: $white;
    border-radius: map-get($map: $scale, $key: 12);
    gap: map-get($map: $scale, $key: 16);
    .shopsFiltersContainer {
      display: flex;
      justify-content: space-between;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: map-get($map: $scale, $key: 16);
      border-bottom: map-get($map: $scale, $key: 1) solid
        map-get($map: $grey-color, $key: grey-200);
      h4 {
        @extend .hero-text;
      }
      .radioNavigation {
        display: flex;
        flex-direction: row;
        gap: map-get($map: $scale, $key: 16);
        :global(.ant-radio-button-wrapper) {
          border-radius: map-get($map: $scale, $key: 16);
          border-inline-start-width: 1px;
          &::before {
            content: unset;
            border-inline-start-width: map-get($map: $scale, $key: 1);
          }
        }
      }
    }
  }
}

.shopList {
  display: flex;
  flex-direction: column;
  gap: map-get($map: $scale, $key: 8);
  margin-bottom: map-get($map: $scale, $key: 12);
  width: 70%;
  max-height: 1200px;
  overflow-y: auto;
  overflow-x: hidden;
}
