@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../global.module.scss";
@use "@anarock/ar-common-react/dist/styles/colorClasses.module" as *;

:local(.tableContainer) {
  margin: map-get($map: $scale, $key: 24);
}

:local(.paginationContainer) {
  display: flex;
  justify-content: center;
  padding-top: map-get($map: $scale, $key: 16);
}

:local(.pageWrapper) {
  width: 946px;
}

:local(.outerContainer) {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto;
}

:local(.pageTitle) {
  padding: map-get($map: $scale, $key: 20);
  border-bottom: 1px solid #ededed;
  background-color: $white;
  border-radius: map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) 0 0;
  font-weight: 600;
  font-size: map-get($map: $scale, $key: 16);
  line-height: map-get($map: $scale, $key: 20);
  color: #101721;
  &:local(.modalStyles) {
    padding-top: 0px;
  }
}

:local(.innerContainer) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

:local(.fullWidth) {
  width: 100%;
}

:local(.halfWidth) {
  width: 50%;
}

:local(.reqContainer) {
  background-color: $white;
  padding: map-get($map: $scale, $key: 20);
  border-radius: 0 0 map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16);
  margin-bottom: map-get($map: $scale, $key: 20) !important;
  &:local(.modalStyles) {
    max-height: 450px;
    overflow-y: scroll;
    padding-right: map-get($map: $scale, $key: 12);
  }
}

:local(.documentBox) {
  padding-bottom: map-get($map: $scale, $key: 24);
}

:local(.documentTitle) {
  @extend .sub-hero-text;
  padding-bottom: map-get($map: $scale, $key: 16);
  margin: 0;
}

:local(.approvedCellUi) {
  width: 100%;
  :global(.ant-tag) {
    width: 100%;
  }
  :global(.display-flex-space-between) {
    :global(.ant-tag) {
      width: 140px;
    }
  }
}

:local(.flexTableCell) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: map-get($map: $scale, $key: 8);
}

:local(.securedOptionsDropDown) {
  :global(.ant-dropdown-menu) {
    padding: 8px 0;
    :global(.ant-dropdown-menu-item) {
      padding: map-get($map: $scale, $key: 16) !important;
    }
  }
}

.breadCrumb {
  margin: map-get($map: $scale, $key: 16) 0;
  :local(.breadCrumbItem) {
    font-weight: 500;
    color: black;
  }
  :local(.breadCrumbItem:hover) {
    cursor: pointer;
  }
}

:local(.moreDocuments) {
  :global(.ant-upload-list-item-done) {
    display: none !important;
  }
}

:local(.summaryCard) {
  width: 898px !important;
  :local(.heading) {
    display: flex;
    justify-content: space-between;
    padding: map-get($map: $scale, $key: 16) 0;

    h4 {
      @extend .hero-text;
    }
    button {
      span {
        margin-inline-start: 0;
      }
    }
  }
  :local(.detailContainer) {
    @extend .dashedBorder;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: map-get($map: $scale, $key: 16);
    // padding: map-get($map: $scale, $key: 16);
    &:local(.noBorder) {
      border: none;
      padding: 0;
    }
    :local(.detailsChild) {
      width: 130px;
      margin-bottom: 12px;
      padding-right: 114px;
      text-overflow: ellipsis;
      overflow: hidden;
      // &:nth-child(9n + 1):nth-last-child(-n + 9),
      // &:nth-child(9n + 1):nth-last-child(-n + 9) ~ .detailsChild {
      //   margin-bottom: 0;
      // }
      &:nth-child(4n) {
        padding-right: 0;
      }
    }
  }
  :local(.flexAlignment) {
    display: flex;
    flex-wrap: wrap;
  }
  :local(.documentDetailContiner) {
    @extend .dashedBorder;
    margin-bottom: map-get($map: $scale, $key: 16);
    padding: map-get($map: $scale, $key: 16);
  }
  :local(.proposedDataWrapper) {
    padding: map-get($map: $scale, $key: 16);
  }
  :local(.approvedDataWrapper) {
    padding: map-get($map: $scale, $key: 16);
    background-color: map-get($map: $grey-color, $key: grey-100);
    border-radius: 0 0 map-get($map: $scale, $key: 8) map-get($map: $scale, $key: 8);
    width: 100%;
  }
}

:local(.mandatoryColor) {
  color: map-get($map: $red-color, $key: red-400);
}

:local(.tableCellEllipsis) {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
:local(.tableCommentCellEllipsis) {
  width: 100%;
}
:local(.subTableHeading) {
  font-weight: 400;
}
:local(.summarySubHeading) {
  @extend .sub-hero-text;
  padding: map-get($map: $scale, $key: 24) 0;
  margin: 0;
}
.bold {
  font-weight: 500 !important;
}
.strikeThrough {
  text-decoration: line-through;
}
:local(.tableContainer) {
  tr {
    cursor: pointer;
  }
}