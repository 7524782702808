@use "../../../../../global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.invoiceDetails) {
  height: 500px;
  :local(.detailContainer) {
    @extend .dashedBorder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: map-get($map: $scale, $key: 16);
  }
}
:local(.invoiceModal) {
  :global(.ant-modal-footer) {
    padding-top: map-get($map: $scale, $key: 80);
  }
}
:local(.iconsPadding) {
  padding-left: map-get($map: $scale, $key: 8);
  cursor: pointer;
}
:local(.flexTableCell) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: map-get($map: $scale, $key: 8);
}