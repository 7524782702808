@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../../global.module.scss" as *;

:local(.loginFormWrapper) {
  padding: map-get($map: $scale, $key: 24);
  border-radius: map-get($map: $scale, $key: 4);
  :local(.hide) {
    display: none;
  }
  :local(.submitButton) {
    margin-top: map-get($map: $scale, $key: 24);
    &:focus {
      border: none;
    }
  }
  :local(.resetButton) {
    margin-top: map-get($map: $scale, $key: 4);
    text-decoration: underline;
    span {
      text-decoration: underline;
    }
  }
  :local(.btnContainer) {
    @extend .display-flex-center;
  }
  :local(.headings) {
    width: 300px;
    h2 {
      @extend .dynamic-numbers;
      margin-bottom: map-get($map: $scale, $key: 12);
    }
    h4 {
      @extend .sub-hero-text-regular;
      margin-bottom: map-get($map: $scale, $key: 40);
    }
    @media (min-width: $breakpoint-tablet) {
      width: 340px;
    }
  }
  :local(.emailContainer) {
    @extend .display-flex-space-between;
    margin-bottom: map-get($map: $scale, $key: 24);
    p {
      @extend .sub-hero-text;
    }
  }
}
