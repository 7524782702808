@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.wipOnboardingWrapper) {
  width: 800px;
  display: flex;
  margin: auto;
  flex-direction: column;
  :local(.footer) {
    padding: map-get($map: $scale, $key: 24) 0;
    button {
      width: 200px;
    }
  }
}
:local(.onboardingInnerContainer) {
  display: flex;
  & > div {
    width: 40% !important;
    :global(.custom-input-box) {
      max-width: 100%;
      min-width: 100%;
    }
  }
  // & > div:nth-child(5) {
  //   width: 100% !important;
  // }
}

:local(.reqOuterContainer) {
  background-color: $white;
  padding: 20px;
  border-radius: 0 0 16px 16px;
  margin-bottom: 20px !important;
  min-height: 420px;
  justify-content: flex-start !important;
}
:local(.headingContainer) {
  padding: map-get($map: $scale, $key: 16) 0;
}
