@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.shopListContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 820px;
  gap: map-get($map: $scale, $key: 8);
  margin-bottom: map-get($map: $scale, $key: 12);
  align-items: center;
  .headingText {
    @extend .hero-text-regular;
    margin: map-get($map: $scale, $key: 8) 0;
    width: 100%;
    &.searchText {
      margin-top: map-get($map: $scale, $key: 16);
    }
  }
  .shopsWrapper {
    display: flex;
    flex-direction: column;
    gap: map-get($map: $scale, $key: 12);
    padding: map-get($map: $scale, $key: 12);
  }
  :local(.shopCard) {
    :hover {
      cursor: pointer;
    }
  }
}
.accessError {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
