@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.filterBar) {
  padding: 16px 0px !important;
  margin-top: 0 !important;
  z-index: 100;
  position: relative;
  :local(.navRangeSlider) {
    margin: 0 24px;
  }
  :local(.dropdown) {
    border: 1px solid map-get($map: $grey-color, $key: grey-400);
    border-radius: map-get($map: $scale, $key: 4);
    padding: map-get($map: $scale, $key: 28) map-get($map: $scale, $key: 8);
    padding-bottom: 0;
    font-size: 14px;
    position: relative;
    height: map-get($map: $scale, $key: 24);
    :local(.label) {
      @extend .sub-hero-text-regular-medium;
      font-size: map-get($map: $font-sizes, $key: 2);
      position: absolute;
      top: 4px;
    }
    .multiselectArrow {
      margin-top: map-get($map: $scale, $key: 4);
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  :local(.dropdown:hover) {
    transition: 1s;
    cursor: pointer;
    border-color: map-get($map: $primary-color, $key: primary-500);
  }
  :local(.dropDownValue) {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    p {
      margin-right: 10px;
    }
    span {
      width: 10px;
      margin: auto;
      margin-right: 0;
    }
  }
  .singleSelectFilter {
    :global(.ant-select-selection-item) {
      font-size: 14px;
    }
  }
  .createRequirement {
    margin: 0 24px;
  }
}
.selectCarpetArea {
  :local(.carpetHeaderPadding) {
    padding: map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) 0;
  }
  :local(.sliderPadding) {
    padding: 0 map-get($map: $scale, $key: 16);
  }
  :local(.minMaxInputHover) {
    display: flex;
    gap: map-get($map: $scale, $key: 60);
    align-items: center;
    padding: map-get($map: $scale, $key: 8) 0;
  }
  :global(.custom-input-box) {
    min-width: 112px;
    input {
      width: 112px;
    }
  }
}

:local(.shopListLayout) {
  background-color: $white;
}

.filterShimmerBar {
  display: flex;
  gap: map-get($map: $scale, $key: 16);
  padding: map-get($map: $scale, $key: 16);
  background-color: $white;
}
.filterShimmerItem {
  height: 54px !important;
}

:local(.filterSearchBar) {
  background-color: $white !important;
  padding: 6px 11px;
  :global(.ant-input) {
    background-color: $white !important;
  }
}
:local(.radioSelect) {
  &:global(.ant-radio-group) {
    width: 380px;
    padding: map-get($map: $scale, $key: 16);
    gap: map-get($map: $scale, $key: 12);
  }
}
:local(.moreFilterRadioSelect) {
  &:global(.ant-radio-group) {
    width: auto;
    padding: map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) 0;
  }
}
:local(.datePickerWrapper) {
  display: flex;
}
:local(.datePickerSeparator) {
  @extend .text;
  padding: map-get($map: $scale, $key: 16);
}
:local(.tabWrapper) {
  width: 580px !important;
  min-height: 300px !important;
  :global(.ant-tabs-nav) {
    padding: map-get($map: $scale, $key: 16) 0 map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16) !important;
  }
  :global(.ant-tabs-content-holder) {
    display: flex !important;
    padding: map-get($map: $scale, $key: 16) !important;
    background-color: map-get($map: $light-color, $key: light-50) !important;
  }
  :global(.ant-tabs-nav-wrap) {
    padding: 0 !important;
  }
  :global(.ant-tabs-tab) {
    padding: 0 map-get($map: $scale, $key: 16) 0 0 !important;
  }
  :global(.ant-tabs-tabpane) {
    padding-left: 0 !important;
  }
  :local(.selectCarpetArea) {
    margin: 0;
  }
  :global(.ant-slider-horizontal) {
    margin: map-get($map: $scale, $key: 12) !important;
  }
  :global(.customRangeSliderContainer) {
    max-width: 360px;
  }
  :global(.ant-tabs-tab-btn) {
    font-weight: 500 !important;
    color: map-get($map: $grey-color, $key: grey-900) !important;
    font-size: map-get($map: $font-sizes, $key: 4) !important;
  }
  :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: map-get($map: $primary-color, $key: primary-400) !important;
  }
}
:local(.radioSelectOptions) {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
:local(.moreFiltersTitle) {
  display: flex;
  justify-content: space-between;
  padding: map-get($map: $scale, $key: 16);
  border-bottom: 1px solid map-get($map: $grey-color, $key: grey-300);
}
:local(.rangeSlider) {
  margin: 0 map-get($map: $scale, $key: 12);
}
:local(.nestedFilter) {
  width: 216px;
  margin: map-get($map: $scale, $key: 16) 0 map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16);
  overflow-y: auto;
  max-height: 250px;
}
:local(.filterSeparator) {
  height: map-get($map: $scale, $key: 52);
  width: map-get($map: $scale, $key: 2);
  background: map-get($map: $primary-color, $key: primary-400);
  display: block;
  margin-left: map-get($map: $scale, $key: 16);
}
:local(.filterButtonContainer) {
  padding: map-get($map: $scale, $key: 16);
  border-top: 1px solid map-get($map: $grey-color, $key: grey-300);
  button:last-child {
    max-width: 100px;
  }
}

:local(.selected) {
  border: 1px solid map-get($map: $primary-color, $key: primary-400) !important;
}
:local(.singleSelectSelected) {
  :global(.ant-select-selector) {
    border: 1px solid map-get($map: $primary-color, $key: primary-400) !important;
  }
}

:local(.filterEllipsisWidth) {
  width: 110px;
}
