@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;


.emptyState{
  @extend .display-flex-column-center;
  margin-top: map-get($map: $scale, $key: 56);
  .emptyText{
    @extend .display-flex-column-center;
    margin: map-get($map: $scale, $key: 16);
    h4{
        font-weight: 500;
    }
  }
}