@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.shopInfoCardWrapper {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 8px;
  .shopImageInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    padding: map-get($map: $scale, $key: 12);
  }
  .shopInfo {
    margin-bottom: map-get($map: $scale, $key: 12);
    h5 {
      @extend .hero-text;
    }
    p {
      @extend .sub-hero-text-regular-medium;
    }
  }
  .carousel {
    > div {
      max-height: 400px;
    }
  }
  :local(.shopStatus) {
    display: flex;
    justify-content: space-between;
    margin-bottom: map-get($map: $scale, $key: 12);
    align-items: center;
    .innerBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    h5 {
      @extend .sub-hero-text-regular-medium;
      margin-bottom: map-get($map: $scale, $key: 4);
    }
    span {
      display: flex;
      justify-content: center;
      gap: map-get($map: $scale, $key: 4);
    }
    :global(.ant-btn) {
      width: auto;
      max-width: 200px;
      padding: map-get($map: $scale, $key: 8);
      min-height: 40px;
    }
  }
  .shopFeatures {
    display: flex;
    justify-content: space-around;
    border: 1px dashed map-get($map: $grey-color, $key: grey-300);
    border-radius: map-get($map: $scale, $key: 8);
    padding: map-get($map: $scale, $key: 12);
    margin-bottom: map-get($map: $scale, $key: 12);
    :global(.ant-divider) {
      border-inline-start: 1px solid map-get($map: $grey-color, $key: grey-300);
      height: auto;
    }
  }
}

.shopFeature {
  max-width: 120px;
  h5 {
    @extend .sub-hero-text-regular-medium;
    font-size: map-get($map: $scale, $key: 12);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    @extend .sub-hero-text-regular;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.geoLocation {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: map-get($map: $scale, $key: 16);
  padding: map-get($map: $scale, $key: 16);
  h4 {
    @extend .sub-hero-text;
    font-size: map-get($map: $font-sizes, $key: 3);
  }
  img {
    margin-top: map-get($map: $scale, $key: 8);
    width: 100%;
  }
}

.shopHistoryCard {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: map-get($map: $scale, $key: 16);
  border-radius: map-get($map: $scale, $key: 16);
  h4 {
    @extend .hero-text;
  }
  .noShopHistory {
    margin: auto;
  }
  .interestedBrands {
    margin: map-get($map: $scale, $key: 16) 0;
    .brandLogos {
      display: flex;
    }
    h5 {
      @extend .sub-hero-text-regular-medium;
    }
    :local(.originalLogo) {
      height: map-get($map: $scale, $key: 40);
      width: map-get($map: $scale, $key: 40);
      border: 1px solid map-get($map: $grey-color, $key: grey-200);
      border-radius: 100%;
    }
    :local(.logoPlaceholder) {
      height: map-get($map: $scale, $key: 16);
      width: map-get($map: $scale, $key: 16);
      border-radius: 100%;
      padding: map-get($map: $scale, $key: 12);
      background-color: map-get($map: $grey-color, $key: grey-500);
      justify-content: center;
      display: flex;
      margin-right: map-get($map: $scale, $key: 2);
    }
  }
  :local(.shopHistory) {
    h5 {
      @extend .sub-hero-text;
    }
    :local(.steps) {
      max-height: 400px;
      overflow-y: auto;
      margin: map-get($map: $scale, $key: 12) 0;
      :global(.ant-steps-item-tail::after) {
        background-color: map-get($map: $light-color, $key: light-300);
        height: map-get($map: $scale, $key: 48) !important;
      }
    }
    :local(.originalLogo) {
      height: map-get($map: $scale, $key: 24);
      width: map-get($map: $scale, $key: 24);
      border: 1px solid map-get($map: $grey-color, $key: grey-200);
      border-radius: 100%;
    }
    :local(.logoPlaceholder) {
      min-width: 12px;
      max-height: 12px;
      border-radius: 100%;
      padding: map-get($map: $scale, $key: 10);
      background-color: map-get($map: $grey-color, $key: grey-500);
      justify-content: center;
      display: flex;
    }
    :global(.ant-steps-item-icon) {
      margin-top: map-get($map: $scale, $key: 4);
    }
    :global(.ant-steps-item-title) {
      @extend .sub-hero-text-regular;
      line-height: 1.5em !important;
      color: black !important;
    }
    :global(.ant-steps-item-description) {
      @extend .sub-hero-text-regular-low;
      line-height: 1.5em !important;
      margin-top: 4px;
      color: map-get($map: $grey-color, $key: grey-550) !important;
    }
  }
}
.bold {
  font-weight: 500 !important;
}
.strikeThrough {
  text-decoration: line-through;
}
.downloadButton {
  color: map-get($map: $primary-color, $key: primary-400);
  cursor: pointer;
  font-size: map-get($map: $font-sizes, $key: 3);
}
.floorPlanBody {
  font-size: map-get($map: $font-sizes, $key: 3);
  margin-top: map-get($map: $scale, $key: 12);
}
.floorPlanElement {
  padding: map-get($map: $scale, $key: 12) 0;
}
.fileInfoContainer {
  display: flex;
  align-items: center;
}
.fileIconBox {
  padding-right: map-get($map: $scale, $key: 4);
  color: map-get($map: $primary-color, $key: primary-400);
}
:local(.copyIcon) {
  color: map-get($map: $grey-color, $key: grey-550);
  position: relative;
  margin-top: -(map-get($map: $scale, $key: 4));
  padding-left: map-get($map: $scale, $key: 4);
}
