@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../../../global.module";

:local(.summaryContainer) {
  padding-bottom: map-get($map: $scale, $key: 24);
}
:local(.summaryCard) {
  width: 898px !important;
  padding: 0 map-get($map: $scale, $key: 24);
  :local(.heading) {
    display: flex;
    justify-content: space-between;
    padding: map-get($map: $scale, $key: 16) 0;

    h4 {
      @extend .hero-text;
    }
    button {
      span {
        margin-inline-start: 0;
      }
    }
  }
  :local(.detailContainer) {
    @extend .dashedBorder;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: map-get($map: $scale, $key: 16);
    &:local(.noBorder) {
      border: none;
      padding: 0;
    }
    :local(.detailsChild) {
      width: 130px; /* Adjust the width and margin as needed */
      margin-bottom: 12px;
      padding-right: 114px;
      text-overflow: ellipsis;
      overflow: hidden;
      // &:nth-child(6n + 1):nth-last-child(-n + 6),
      // &:nth-child(6n + 1):nth-last-child(-n + 6) ~ .detailsChild {
      //   margin-bottom: 0;
      // }
      &:nth-child(4n) {
        padding-right: 0;
      }
    }
  }
  :local(.flexAlignment) {
    display: flex;
    flex-wrap: wrap;
  }
  :local(.proposedDataWrapper) {
    padding: map-get($map: $scale, $key: 16);
  }
  :local(.approvedDataWrapper) {
    padding: map-get($map: $scale, $key: 16);
    background-color: map-get($map: $grey-color, $key: grey-100);
    border-radius: 0 0 map-get($map: $scale, $key: 8) map-get($map: $scale, $key: 8);
    width: 100%;
  }
}
