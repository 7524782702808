@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.notificationsWrapper {
  width: 450px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: visible;
  :global(.ant-divider) {
    margin: 0;
  }
  .notificationLogo {
    height: 20px;
    width: 20px;
  }
}

.notification {
  justify-content: space-around;
  width: 100%;
  :hover {
    cursor: pointer;
  }
  .notificationContent {
    display: flex;
    flex-direction: column;
    gap: map-get($map: $scale, $key: 8);
    width: 80%;
    align-items: self-start;
    h4 {
      font-weight: 500 !important;
    }
    p {
      @extend .sub-hero-text-regular-medium;
      font-size: map-get($map: $font-sizes, $key: 2);
    }
  }
  .notificationShop {
    height: 50px;
    width: 100px;
    border: 1px solid map-get($map: $grey-color, $key: grey-100);
    border-radius: map-get($map: $scale, $key: 8);
  }
}
.countBadge {
    position: absolute;
    right: 74px;
    top: 14px;
    border-color: transparent;
    box-shadow: transparent 0px 0px 0px 1px inset;
}
