@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.footer) {
  display: flex;
  justify-content: left;
  width: 100%;
  gap: map-get($map: $scale, $key: 16);
  :global(.ant-picker) {
    width: 160px;
    height: map-get($map: $scale, $key: 40);
  }
  :global(.custom-input-box) {
    width: 160px;
    min-width: 160px;
    padding: 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    :global(.ant-input-number-group-addon) {
      width: calc(100% - 19px);
      padding: 6px 8px 0 0;
    }
    :global(.ant-input-number-input) {
      padding: 6px 0px 0px 8px;
    }
  }
}

:local(.updateModal) {
  :local(.wrapper) {
    height: 400px;
    overflow-y: scroll;
  }
  :global(.ant-checkbox-wrapper) {
    padding: map-get($map: $scale, $key: 16) 0 !important;
    span {
      padding-inline-start: 0px;
    }
  }
}
