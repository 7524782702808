@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.listingPage) {
  width: 1200px;
  margin: auto;
  .noOfReq {
    padding: map-get($map: $scale, $key: 12) 0;
  }
  .requirments {
    display: flex;
    justify-content: space-between;
    max-height: calc(100vh - 260px);
    .allBrands {
      display: flex;
      flex-direction: column;
      width: 30%;
      margin-right: map-get($map: $scale, $key: 4);
      // max-height: calc(100vh - 250px);
      // position: absolute;
      // max-width: 360px;
      padding: 0 map-get($map: $scale, $key: 24) 0 0;
      overflow-y: scroll;
      z-index: 20;
    }
    .selectedBrand {
      display: flex;
      flex-direction: column;
      max-width: min-content;
      width: 75%;
      // padding-left: 400px;

      overflow-y: scroll;
      // position: fixed;
    }
    .currentBrand {
      width: 100%;
      margin: 0 0 map-get($map: $scale, $key: 12) 0;
    }
    .brandCardWrapper {
      margin-bottom: map-get($map: $scale, $key: 12);
      width: 100%;
      .brandCard {
        width: 100%;
      }
    }
  }
  .viewDetails {
    text-decoration: none;
  }
}
.createRequirement {
  margin: 0 24px;
}

.moveShopsFooter {
  margin: map-get($map: $scale, $key: 20) 0 0 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

:local(.shopListLayout) {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 12px;
  align-items: center;
  margin: map-get($map: $scale, $key: 12) 0;
  .noShops {
    margin: 52px 0;
  }
}

:local(.empty) {
  display: flex;
  flex-direction: column;
  height: 74vh;
  justify-content: center;
}

:local(.loiSignedLayout) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 828px;
  .otherCardsWrapper {
    margin-top: map-get($map: $scale, $key: 12);
    display: flex;
    width: 100%;
    column-gap: map-get($map: $scale, $key: 12);
    justify-content: space-between;
    .shopLeftPane {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: map-get($map: $scale, $key: 12);
      margin-bottom: map-get($map: $scale, $key: 12);
      div {
        width: auto;
      }
    }
    .shopRightPane {
      width: 50%;
      display: flex;
      flex-direction: column;
      div {
        width: auto;
      }
    }
  }
  .loiHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 map-get($map: $scale, $key: 12);
    svg {
      background-color: map-get($map: $primary-color, $key: primary-100);
      border: 4px solid map-get($map: $primary-color, $key: primary-200);
      border-radius: 100%;
      padding: map-get($map: $scale, $key: 2);
    }
  }
}
:local(.wipButton) {
  width: 140px !important;
  &:global(.ant-btn-loading) {
    width: 140px !important;
  }
}
