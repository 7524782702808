@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.importSheetModal) {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  :local(.uploadIcon) {
    svg {
      width: map-get($map: $scale, $key: 80);
      height: map-get($map: $scale, $key: 80);
      color: #ededed;
    }
    margin-bottom: map-get($map: $scale, $key: 32);
  }
  p {
    text-align: center;
  }
  &.errorContainer {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
  }
  :local(.failedText) {
    color: map-get($map: $red-color, $key: red-400);
    padding-bottom: map-get($map: $scale, $key: 8);
  }
  :local(.fixedHeight) {
    height: fit-content;
    width: 500px;
  }
}
:local(.importSheetFooter) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  :local(.footerBtn) {
    max-width: 176px;
  }
  :local(.footerBtnLg) {
    max-width: 296px;
  }
}

:local(.height50) {
  padding: map-get($map: $scale, $key: 20);
  border: 1px solid #e0e0e0;
  border-radius: map-get($map: $scale, $key: 4);
  width: 500px;
  margin-bottom: map-get($map: $scale, $key: 20);
}
