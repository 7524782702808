@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.createRequirementWrapper) {
  min-width: auto !important;
  width: 800px !important;
  margin: auto !important;
  background: none !important;
  .breadCrumb {
    margin: 12px;
    :local(.breadCrumbItem) {
      font-weight: 500;
      color: black;
    }
    :local(.breadCrumbItem:hover) {
      cursor: pointer;
    }
  }
  :global(.ant-input) {
    text-overflow: ellipsis;
  }

  :local(.formTitle) {
    @extend .hero-text;
    padding: 20px;
    border-bottom: 1px solid map-get($map: $grey-color, $key: grey-400);
    background-color: $white;
    border-radius: 16px 16px 0 0;
  }
  :local(.subHeading) {
    @extend .sub-hero-text-regular-medium;
    font-weight: 500;
  }
  :local(.reqOuterContainer) {
    background-color: $white;
    padding: 20px !important;
    border-radius: 0 0 16px 16px !important;
    margin-bottom: 20px !important;
  }
  :local(.addReqButton) {
    border-radius: 8px !important;
    margin-bottom: map-get($map: $scale, $key: 12);
  }
  :global(.ant-form-item-explain-error) {
    min-width: 200px !important;
  }
  :global(.ant-form-item-extra) {
    min-width: 200px !important;
    color: #ff4d4f;
    font-size: 12px;
    margin-top: -4px;
  }
  .unit {
    @extend .sub-hero-text-regular-low;
    color: map-get($map: $grey-color, $key: grey-600);
  }
}

:local(.brandLogo) {
  display: flex;
  align-items: center;
  gap: map-get($map: $scale, $key: 4);
  .brandLabel {
    margin: auto;
  }
  .brandPrefix {
    border-radius: 100%;
    width: map-get($map: $scale, $key: 40);
    height: map-get($map: $scale, $key: 40);
    border: 1px solid map-get($map: $grey-color, $key: grey-300);
  }
}
.brandImageLogo {
  width: map-get($map: $scale, $key: 24);
  height: map-get($map: $scale, $key: 24);
}
.brandImage {
  border-radius: 100%;
  width: map-get($map: $scale, $key: 40);
  height: map-get($map: $scale, $key: 40);
  border: 1px solid map-get($map: $grey-color, $key: grey-300);
}
.placeholderLogo {
  width: map-get($map: $scale, $key: 40);
  height: map-get($map: $scale, $key: 40);
  border-radius: 100%;
  background-color: map-get($map: $grey-color, $key: grey-500);
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid map-get($map: $grey-color, $key: grey-300);
}
:local(.reqInnerContainer) {
  display: flex;
  margin-bottom: 24px;
  & > div {
    width: 40% !important;
  }
}
