@use "@anarock/ar-common-react/dist/styles/index.module.scss" as *;
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.brokenApp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: auto;
  svg {
    width: map-get($map: $scale, $key: 140);
    height: map-get($map: $scale, $key: 140);
  }
  h3 {
    @extend .hero-text;
  }
}
