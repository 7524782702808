@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../../../global.module.scss";

.greenCheckBox {
  svg {
    color: map-get($map: $green-color, $key: green-400);
  }
}
.required {
  color: map-get($map: $grey-color, $key: grey-550) !important;
}
:local(.documentWrapper){
  margin: map-get($map: $scale, $key: 8) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:local(.documentNameWrapper){
  display: flex;
  align-items: center;
}
:local(.documentTitle){
  font-size: map-get($map: $font-sizes, $key: 2);
  margin-left: map-get($map: $scale, $key: 16);
}
:local(.documentActionWrapper){
  margin-right: map-get($map: $scale, $key: 4);
}
:local(.primaryColorIcon) {
  svg{
    color: map-get($map: $primary-color, $key: primary-400);
    width: map-get($map: $scale, $key: 16);
    height: map-get($map: $scale, $key: 16);
  }
}
:local(.ml24){
  margin-left: map-get($map: $scale, $key: 24);
}
:local(.mt16){
  margin-top: map-get($map: $scale, $key: 16);
}
:local(.fullWidth){
  width: 100%;
}
:local(.halfWidth){
  width: 50%;
}
:local(.errorColor){
  color: map-get($map: $red-color, $key: red-400) !important;
}
:local(.errorMessage){
  font-size: map-get($map: $font-sizes, $key: 2);
  margin-bottom: map-get($map: $scale, $key: 8);
  color: map-get($map: $red-color, $key: red-400) !important;
}