@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

// .matchignShopContainer {
//   // max-height: 1250px;
//   overflow-x: hidden;
//   // overflow-y: scroll;
// }
.nearbyShopList {
  padding-top: map-get($map: $scale, $key: 28);
  .otherText {
    text-align: left;
    align-self: baseline;
    padding: map-get($map: $scale, $key: 16) map-get($map: $scale, $key: 16)
      map-get($map: $scale, $key: 8) map-get($map: $scale, $key: 24);
  }
}

:local(.shopCard) {
  margin: map-get($map: $scale, $key: 8) map-get($map: $scale, $key: 12);
}

:local(.shopUpdated) {
  @extend .sub-hero-text-regular-medium;
  color: map-get($map: $emerald-color, $key: emerald-400);
}

:local(.shopExpired) {
  @extend .sub-hero-text-regular-medium;
  color: map-get($map: $red-color, $key: red-400);
}

:local(.updatedIcon) {
  &:global(.anticon) {
    svg {
      height: map-get($map: $scale, $key: 16);
      width: map-get($map: $scale, $key: 16);
    }
  }
}
