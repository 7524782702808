@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;
// @use "../../../global.module.scss" as *;

:local(.forgotSuccess) {
  text-align: center;
  p {
    @extend .sub-hero-text-regular;
    :local(.resendButton) {
      width: fit-content !important;
      span {
        text-decoration: underline;
      }
    }
  }
  :local(.icon) {
    font-size: map-get($map: $scale, $key: 48);
    color: map-get($map: $emerald-color, $key: emerald-500);
    padding-bottom: map-get($map: $scale, $key: 24);
  }
  
}
