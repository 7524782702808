@use "@anarock/ar-common-react/dist/styles/partials" as *;
@use "../../global.module.scss";
@use "@anarock/ar-common-react/dist/styles/colorClasses.module" as *;

:local(.tableContainer) {
  margin: map-get($map: $scale, $key: 24);
}

:local(.paginationContainer) {
  display: flex;
  justify-content: center;
  padding-top: map-get($map: $scale, $key: 16);
}

.breadCrumb {
  margin: map-get($map: $scale, $key: 16) 0;
  :local(.breadCrumbItem) {
    font-weight: 500;
    color: black;
  }
  :local(.breadCrumbItem:hover) {
    cursor: pointer;
  }
}

:local(.flexTableCell) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: map-get($map: $scale, $key: 8);
}

:local(.plusMinusIcon) {
  font-size: map-get($map: $font-sizes, $key: 5);
}

:local(.subTableHeading) {
  font-weight: 400;
}
:local(.reportTableContainer) {
  tbody {
    tr:first-child {
      td {
        padding: 0 !important;
      }
    }
    tr:nth-child(2) {
      td {
        padding: map-get($map: $scale, $key: 16) !important;
        font-weight: 500;
      }
    }
  }
}
:local(.historyIcon) {
  svg {
    width: map-get($map: $scale, $key: 16);
    height: map-get($map: $scale, $key: 16);
    color: map-get($map: $primary-color, $key: primary-400);
  }
}
:local(.tableCellEllipsis) {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}