@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.userAnalytics) {
  display: flex;
  justify-content: space-between;
  padding: map-get($map: $scale, $key: 28);
  padding-right: 0;
  background: linear-gradient(
    267.69deg,
    map-get($map: $primary-color, $key: primary-500) -742.77%,
    $white 122.22%
  );
  border: 1px solid $white;
  border-radius: 16px;
  width: 100% !important;
  .analyticsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    .greetings {
      display: flex;
      gap: map-get($map: $scale, $key: 12);
      h4 {
        @extend .hero-text-regular;
        strong {
          font-weight: 500;
        }
      }
      p {
        @extend .sub-hero-text-regular-medium;
      }
    }
    .analyticsCount {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: map-get($map: $scale, $key: 12);
      .target {
        h1 {
          @extend .hero-text;
          font-size: map-get($map: $font-sizes, $key: 8);
          width: max-content;
          margin-bottom: map-get($map: $scale, $key: 8);
        }
        .selectQuarter {
          text-decoration: none;
          span {
            @extend .sub-hero-text-regular-medium;
          }
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
      .analytics {
        display: flex;
        gap: map-get($map: $scale, $key: 16);
        h3 {
          @extend .hero-text;
        }
        p {
          @extend .sub-hero-text-regular-medium;
          width: max-content;
        }
      }
    }
  }
}

:local(.quarterSelect) {
  &:global(.ant-radio-group) {
    width: 200px;
    padding: 12px;
    gap: 8px;
  }
}
.brandsContainer {
  width: 100% !important;
  padding: map-get($map: $scale, $key: 20);
  .interestedRequirementContainer {
    display: flex;
    flex-direction: column;
    gap: map-get($map: $scale, $key: 12);
    width: auto;
    h3 {
      @extend .hero-text;
      font-weight: 500;
    }
    .brandStatsWrapper {
      display: flex;
      width: 100%;
      gap: map-get($map: $scale, $key: 12);
      .brandStatsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        .brandLogo {
          border-radius: 100%;
          height: map-get($map: $scale, $key: 100);
          width: map-get($map: $scale, $key: 100);
          border: 1px solid map-get($map: $grey-color, $key: grey-300);
        }
        .brandLogoPlaceholder {
          border-radius: 100%;
          height: map-get($map: $scale, $key: 100);
          width: map-get($map: $scale, $key: 100);
          border: 1px solid map-get($map: $grey-color, $key: grey-300);
          display: flex;
          justify-content: center;
          align-items: center;
          background: map-get($map: $grey-color, $key: grey-500);
          img {
            height: map-get($map: $scale, $key: 40);
            width: map-get($map: $scale, $key: 40);
          }
        }
        .brandStats {
          display: flex;
          flex-direction: column;
          gap: map-get($map: $scale, $key: 8);
          width: 70%;
          background: $white;
          box-shadow: 0px 4px 4px rgba(102, 102, 102, 0.1);
          padding: map-get($map: $scale, $key: 8)
            map-get($map: $scale, $key: 16);
          border-radius: map-get($map: $scale, $key: 12);
          position: relative;
          margin-top: -(map-get($map: $scale, $key: 20));
          div {
            display: flex;
            justify-content: space-between;
            p {
              @extend .hero-text;
            }
            span {
              @extend .sub-hero-text-regular-medium;
            }
          }
          p {
            font-weight: 500;
          }
        }
      }
    }
  }
}

:local(.new) {
  color: #6161ff;
}

:local(.shared) {
  color: #9254e3;
}

:local(.shortlisted) {
  color: #f3aa07;
}

:local(.visitDone) {
  color: #10ac60;
}

:local(.inNegotiation) {
  color: #1f69ff;
}

:local(.loiSigned) {
  color: #6161ff;
}

:local(.rejected) {
  color: #c44829;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  gap: map-get($map: $scale, $key: 16);
  margin-left: map-get($map: $scale, $key: 12);
  padding: map-get($map: $scale, $key: 12);
  padding-right: map-get($map: $scale, $key: 20);
  border: map-get($map: $scale, $key: 1) solid
    map-get($map: $grey-color, $key: grey-200);
  border-radius: map-get($map: $scale, $key: 8);
  height: fit-content;
  :global(.ant-checkbox-wrapper) {
    span {
      padding-inline-start: 0px;
    }
    padding-right: map-get($map: $scale, $key: 20);
  }
  .filter {
    display: flex;
    flex-direction: column;
    gap: map-get($map: $scale, $key: 4);
    h4 {
      @extend .sub-hero-text-regular-medium;
      font-weight: map-get($map: $font-weights, $key: 500);
    }
    :global(.ant-checkbox-group) {
      overflow: hidden scroll;
      max-height: map-get($map: $scale, $key: 400);
    }
    .filterData {
      padding-right: map-get($map: $scale, $key: 20);
      max-height: map-get($map: $scale, $key: 400);
      overflow-x: hidden;
      overflow-y: scroll;
      span {
        @extend .sub-hero-text-regular;
      }
    }
  }
  h4 {
    @extend .hero-text;
    font-weight: map-get($map: $font-weights, $key: 500);
    font-size: map-get($map: $font-sizes, $key: 3);
  }
}

.shopsHeader {
  display: flex;
  align-items: center;
  padding: map-get($map: $scale, $key: 16);
  border-bottom: map-get($map: $scale, $key: 1) solid
    map-get($map: $grey-color, $key: grey-200);
  margin-bottom: map-get($map: $scale, $key: 12);
  .brandLogo {
    border-radius: 100%;
    height: map-get($map: $scale, $key: 40);
    width: map-get($map: $scale, $key: 40);
    margin: 0 map-get($map: $scale, $key: 8);
  }
  .brandLogoPlaceholder {
    border-radius: 100%;
    height: map-get($map: $scale, $key: 40);
    width: map-get($map: $scale, $key: 40);
    border: 1px solid map-get($map: $grey-color, $key: grey-300);
    display: flex;
    justify-content: center;
    align-items: center;
    background: map-get($map: $grey-color, $key: grey-500);
    margin: 0 map-get($map: $scale, $key: 8);
    img {
      height: map-get($map: $scale, $key: 16);
      width: map-get($map: $scale, $key: 16);
    }
  }
  h4 {
    @extend .hero-text;
    margin-bottom: map-get($map: $scale, $key: 4);
  }
  p {
    @extend .sub-hero-text-regular-medium;
  }
}

.shopList {
  display: flex;
  flex-direction: column;
  gap: map-get($map: $scale, $key: 8);
  margin-bottom: map-get($map: $scale, $key: 12);
  .noShops {
    margin: auto;
  }
}
