@use "@anarock/ar-common-react/dist/styles/global.module.scss" as *;
@use "@anarock/ar-common-react/dist/styles/partials" as *;

.modalInnerContainer {
  :global(.ant-form-item) {
    margin-bottom: map-get($map: $scale, $key: 12);
  }
  .statusLabel {
    padding: map-get($map: $scale, $key: 8) 0;
  }
  :local(.otherShopsRejected) {
    @extend .sub-hero-text-regular-low;
    width: max-content;
    margin-right: 232px;
  }
  :global(.ant-checkbox-group) {
    padding-bottom: map-get($map: $scale, $key: 24);
    border-bottom: 1px solid map-get($map: $grey-color, $key: grey-200);
  }
  :global(.ant-checkbox-wrapper) {
    display: flex;
    justify-content: space-between;
    > span {
      padding: 0;
    }
  }
  :local(.rejectAllShops) {
    @extend .sub-hero-text-regular-low;
    margin: map-get($map: $scale, $key: 4) 0 0 0;
  }
  label {
    max-width: none !important;
    padding: map-get($map: $scale, $key: 8) 0;
    align-items: center;
  }
}

:local(.optionListBrandImages) {
  margin: map-get($map: $scale, $key: 4) 0 map-get($map: $scale, $key: 8) 0;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  :local(.brandLogo) {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 1px solid map-get($map: $primary-color, $key: primary-100);
  }
  :local(.brandLogoPlaceholder) {
    border-radius: 100%;
    height: map-get($map: $scale, $key: 40);
    width: map-get($map: $scale, $key: 40);
    border: 1px solid map-get($map: $grey-color, $key: grey-300);
    display: flex;
    justify-content: center;
    align-items: center;
    background: map-get($map: $grey-color, $key: grey-500);
    img {
      height: map-get($map: $scale, $key: 16);
      width: map-get($map: $scale, $key: 16);
    }
  }
}

:local(.dragger) {
  h3 {
    @extend .hero-text;
    font-size: map-get($map: $font-sizes, $key: 3);
    padding-bottom: map-get($map: $scale, $key: 16);
  }
}

.copytext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 map-get($map: $scale, $key: 8);
  border: 1px solid map-get($map: $grey-color, $key: grey-300);
  border-radius: map-get($map: $scale, $key: 4);
  background-color: map-get($map: $grey-color, $key: grey-200);
  margin-top: map-get($map: $scale, $key: 12);
  p {
    @extend .sub-hero-text-regular-medium;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .copyButton {
    width: auto;
  }
}
:local(.matchingListModal) {
  :global(.ant-checkbox-wrapper) {
    align-items: center;
  }
}
:local(.selectbox) {
  max-width: 100% !important;
}
:local(.addbrandModal) {
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
}
