@use "@anarock/ar-common-react/dist/styles/global.module.scss";
@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.header) {
  @extend .d-flex;
  align-items: center;
  padding: 4px 48px;
  background-color: map-get($map: $primary-color, $key: primary-600);
  position: sticky;
  top: 0;
  z-index: 1100;
  img {
    width: 132px;
    height: 60px;
    cursor: pointer;
  }
  .otherActions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: map-get($map: $scale, $key: 16);
    align-self: flex-end;
    color: $white;
    margin: auto 0 auto auto;
    height: 24px;
    width: 16px;
    cursor: pointer;
  }
  :global(.ant-tabs) {
    background-color: map-get($map: $primary-color, $key: primary-600);
  }
  :global(.ant-tabs-tab) {
    padding: 12px 16px !important;
    :global(.ant-tabs-tab-btn) {
      margin: auto;
      text-decoration: none;
      color: $white !important;
      font-weight: 500;
    }
  }
  :global(.ant-tabs-nav) {
    :global(.ant-tabs-nav-list) {
      justify-content: space-evenly !important;
    }
    :global(.ant-tabs-ink-bar) {
      background-color: $white;
    }
  }
  .logout {
    height: 16px;
    width: 16px;
    display: inline-flex;
    cursor: pointer;
  }
}

:local(.footer) {
  width: 100%;
  padding: 20px 0px;
}

:local(.arLayout) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
main {
  flex: 1;
}
:local(.dropdownOverlay) {
  z-index: 1100;
  :global(ul.ant-dropdown-menu) {
    margin-top: map-get($map: $scale, $key: 16);
    padding: map-get($map: $scale, $key: 8) 0;
    :global(.ant-dropdown-menu-item) {
      padding: map-get($map: $scale, $key: 16);
    }
  }
}
.dropdownText {
  display: flex;
  gap: 4px;
  align-items: center;
  .multiselectArrow {
    height: 12px;
    width: 12px;
    path {
      fill: #fff;
    }
    &.rotate {
      transform: rotate(180deg);
    }
  }
}
.dropdownAnchor {
  text-decoration: none;
}
