@use "@anarock/ar-common-react/dist/styles/partials" as *;

:local(.searchBox) {
  :global(.ant-spin) {
    margin-top: 0;
  }
}

:local(.searchDropDown) {
  padding: map-get($map: $scale, $key: 24);

  :global(.ant-select-item) {
    padding: map-get($map: $scale, $key: 8) 0;
  }
  :local(.heading) {
    margin-bottom: map-get($map: $scale, $key: 8);
    display: flex;
    justify-content: space-between;
    button {
      font-size: map-get($map: $font-sizes, $key: 2);
    }
    @extend .text;
  }
}
:local(.renderSearchItem) {
  display: flex;
  gap: map-get($map: $scale, $key: 8);
  align-items: center;
  .textContainer {
    width: 90%;
  }
  :local(.title) {
    @extend .sub-hero-text;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    padding-bottom: map-get($map: $scale, $key: 4);
  }
  :local(.subHeading) {
    @extend .sub-hero-text-regular-medium;
    strong {
      color: map-get($map: $grey-color, $key: grey-900);
      font-weight: map-get($map: $font-weights, $key: 500);
    }
  }
  
}
