@use "@anarock/ar-common-react/dist/styles/index.module.scss" as *;
@use "@anarock/ar-common-react/dist/styles/partials" as *;

body {
  background-color: map-get($map: $light-color, $key: light-50);
}

:local(.subHeading) {
  @extend .sub-hero-text-regular-medium;
  font-weight: 500;
  margin-bottom: 20px;
}

:local(.reqInnerContainer) {
  display: flex;
  margin-bottom: 24px;
  div {
    width: 40% !important;
  }
}

:local(.formTitle) {
  @extend .hero-text;
  padding: 20px;
  border-bottom: 1px solid map-get($map: $grey-color, $key: grey-400);
  background-color: $white;
  border-radius: 16px 16px 0 0;
}

:local(.reqOuterContainer) {
  background-color: $white;
  padding: 20px;
  border-radius: 0 0 16px 16px;
  margin-bottom: 20px !important;
}
.dashedBorder {
  border: 1px dashed map-get($map: $grey-color, $key: grey-300);
  border-radius: map-get($map: $scale, $key: 8);
}
.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

:global(.ant-spin) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: map-get($map: $scale, $key: 40);
}

:global(.pac-container) {
  width: 451px !important;
}
:global(.pb-16) {
  padding-bottom: map-get($map: $scale, $key: 16) !important;
}
:global(.pb-24) {
  padding-bottom: map-get($map: $scale, $key: 24) !important;
}
:global(.pb-32) {
  padding-bottom: map-get($map: $scale, $key: 32) !important;
}
:global(.pb-8) {
  padding-bottom: map-get($map: $scale, $key: 8) !important;
}
:global(.pb-4) {
  padding-bottom: map-get($map: $scale, $key: 4) !important;
}

:global(.pb-2) {
  padding-bottom: map-get($map: $scale, $key: 2) !important;
}
:global(.mb-24) {
  margin-bottom: map-get($map: $scale, $key: 24) !important;
}
:global(.mb-8) {
  margin-bottom: map-get($map: $scale, $key: 8) !important;
}
:global(.mb-40) {
  margin-bottom: map-get($map: $scale, $key: 40) !important;
}
:global(.mb-4) {
  margin-bottom: map-get($map: $scale, $key: 4) !important;
}
:global(.mt-32) {
  margin-top: map-get($map: $scale, $key: 32) !important;
}
:global(.mt-8) {
  margin-top: map-get($map: $scale, $key: 8) !important;
}
:global(.pr-4) {
  padding-right: map-get($map: $scale, $key: 4) !important;
}
:global(.pr-16) {
  padding-right: map-get($map: $scale, $key: 16) !important;
}
:global(.pt-4) {
  padding-top: map-get($map: $scale, $key: 4);
}
:global(.pt-2) {
  padding-top: map-get($map: $scale, $key: 2);
}
:global(.py-4) {
  padding: map-get($map: $scale, $key: 4) 0;
}
:global(.my-4) {
  margin: map-get($map: $scale, $key: 4) 0;
}
:global(.px-24) {
  padding: 0 map-get($map: $scale, $key: 24);
}
:global(.px-32) {
  padding: 0 map-get($map: $scale, $key: 32);
}
:global(.py-32) {
  padding: map-get($map: $scale, $key: 32) 0;
}
:global(.p-24) {
  padding: map-get($map: $scale, $key: 24);
}

:global(.px-4) {
  padding: 0 map-get($map: $scale, $key: 4);
}
:global(.pt-16) {
  padding-top: map-get($map: $scale, $key: 16);
}

:global(.pt-24) {
  padding-top: map-get($map: $scale, $key: 24);
}
:global(.pt-20) {
  padding-top: map-get($map: $scale, $key: 20);
}
:global(.pt-32) {
  padding-top: map-get($map: $scale, $key: 32);
}
:global(.pt-40) {
  padding-top: map-get($map: $scale, $key: 40);
}
:global(.py-16) {
  padding: map-get($map: $scale, $key: 16) 0;
}
:global(.py-12) {
  padding: map-get($map: $scale, $key: 12) 0;
}
:global(.py-24) {
  padding: map-get($map: $scale, $key: 24) 0;
}
:global(.pl-8) {
  padding-left: map-get($map: $scale, $key: 8) !important;
}
:global(.pl-4) {
  padding-left: map-get($map: $scale, $key: 4) !important;
}
:global(.pt-8) {
  padding-top: map-get($map: $scale, $key: 8);
}
:global(.py-8) {
  padding: map-get($map: $scale, $key: 8) 0;
}
:global(.pr-8) {
  padding-right: map-get($map: $scale, $key: 8) !important;
}
:global(.pr-24) {
  padding-right: map-get($map: $scale, $key: 24) !important;
}
:global(.pr-20) {
  padding-right: map-get($map: $scale, $key: 20) !important;
}
:global(.pb-20) {
  padding-bottom: map-get($map: $scale, $key: 20) !important;
}
:global(.pb-40) {
  padding-bottom: map-get($map: $scale, $key: 40) !important;
}
:global(.mr-4) {
  margin-right: map-get($map: $scale, $key: 4) !important;
}

:global(.mr-8) {
  margin-right: map-get($map: $scale, $key: 8) !important;
}

:global(.ant-table-wrapper .ant-table-thead > tr > th) {
  background: map-get($map: $primary-color, $key: primary-200) !important;
}

:global(.ant-table-wrapper .ant-table-tbody > tr:nth-child(even) > td) {
  background: map-get($map: $grey-color, $key: grey-100);
}

:global(.ant-table-wrapper .ant-table-tbody > tr:nth-child(odd) > td) {
  background: white;
}

:global(.ant-table-wrapper .ant-table-tbody > tr.ant-table-row > td) {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
:global(.ant-table-wrapper .ant-table-tbody > tr.ant-table-row) {
  td:first-child {
    border-left: 1px solid white;
  }
  td:last-child {
    border-right: 1px solid white;
  }
}

:global(.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td) {
  border-top: 1px solid map-get($map: $primary-color, $key: primary-400);
  border-bottom: 1px solid map-get($map: $primary-color, $key: primary-400);
}
:global(.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover) {
  td:first-child {
    border-left: 1px solid map-get($map: $primary-color, $key: primary-400) !important;
  }
  td:last-child {
    border-right: 1px solid map-get($map: $primary-color, $key: primary-400) !important;
  }
}

ant-table [vt] > table > .ant-table-tbody > tr > td {
  padding: 8px;
}

:global(.multiselectArrow) {
  height: 12px;
  width: 12px;
  path {
    fill: rgb(0, 0, 0, 0.88);
  }
  &:global(.rotate) {
    transform: rotate(180deg);
  }
}
:global(.noHoverBgChange) {
  :global(.ant-dropdown-menu-item:hover) {
    background-color: $white !important;
  }
}
:global(.emptyBoxContainer) {
  &::after {
    content: "";
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: transparent;
    border-left: 1px dashed map-get($map: $primary-color, $key: primary-400);
    display: inherit;
    height: 36px;
    margin-left: 9px;
    position: relative;
  }
  :global(.emptyBox) {
    height: 16px;
    width: 16px;
    border-radius: 14px;
    background-color: map-get($map: $primary-color, $key: primary-400);
    display: inline-block;
  }
  &:global(.end) {
    &::before {
      content: "";
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: transparent;
      border-left: 1px dashed map-get($map: $primary-color, $key: primary-400);
      display: inherit;
      height: 32px;
      margin-left: 9px;
      position: relative;
    }
    &::after {
      display: none;
    }
  }
}
:global(.progressContainer) {
  &::after,
  &::before {
    content: "";
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: transparent;
    border-left: 1px dashed map-get($map: $primary-color, $key: primary-400);
    display: inherit;
    height: 50px;
    margin-left: 9px;
    position: relative;
  }
  :global(.progressBox) {
    height: 13px;
    width: 14px;
    border-radius: 14px;
    border: 1px solid map-get($map: $primary-color, $key: primary-400);
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($map: $primary-color, $key: primary-400);
    font-size: 12px;
    padding-top: 1px;
  }
}
:global {
  .textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      padding-inline-end: 0 !important;
    }
  }
  .accessError {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

:global(.dropdownOverlay) {
  z-index: 1100;
  :global(ul.ant-dropdown-menu) {
    margin-top: map-get($map: $scale, $key: 16);
    padding: map-get($map: $scale, $key: 8) 0;
    :global(.ant-dropdown-menu-item) {
      padding: map-get($map: $scale, $key: 16);
    }
  }
}
:global(.bold) {
  font-weight: map-get($map: $font-weights, $key: 500) !important;
}
:global(.fullWidth) {
  width: 100%;
}
:global(.height400) {
  height: 400px;
}
:global(.fullHeight) {
  height: 100%;
}
:global(.height300) {
  height: 300px;
}
:global(.fullMaxWidth) {
  max-width: 100% !important;
}
:global(.sync) {
  color: $white;
  height: map-get($map: $scale, $key: 16);
  width: map-get($map: $scale, $key: 16);
}
:global(.strikeThrough) {
  text-decoration: line-through;
}

:global(.breadCrumb) {
  margin: 12px;
  :global(.breadCrumbItem) {
    font-weight: 500;
    color: black;
  }
  :global(.breadCrumbItem:hover) {
    cursor: pointer;
  }
}
:global(.white-text) {
  color: $white !important;
}
:global(.text-right) {
  text-align: right;
}
:global(.text-center) {
  text-align: center;
}

:global(.modal-btn-container) {
  @extend .display-flex-space-between, :global(.fullWidth);
  :global(.ant-btn) {
    flex-grow: initial !important;
    width: fit-content !important;
    min-width: 200px;
  }
}
:global(.iconSize24) {
  svg {
    width: map-get($map: $scale, $key: 24);
    height: map-get($map: $scale, $key: 24);
  }
}
:global(.zIndex100) {
  z-index: 100;
}

:global(.no-margin) {
  margin: 0;
}
:global(.width-fit-content) {
  width: fit-content !important;
}

:global(.ant-input-custom-error) {
  @extend .sub-hero-text-regular;
  color: #ff4d4f;
}

:global(.icon-hover-bg) {
  :hover {
    border-radius: 4px;
    background: #0000000f;
    cursor: pointer;
  }
}
:global(.hidden) {
  display: none !important;
}
:global(.gap8) {
  gap: map-get($map: $scale, $key: 8);
}
:global(.gap20) {
  gap: map-get($map: $scale, $key: 20);
}
:global(.listModal) {
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
}

:global(.upperCaseText) {
  text-transform: uppercase;
}
:global(.inputBoxPadding) {
  padding: map-get($map: $scale, $key: 12) 0 0 0 !important;
}

:global(.datePicker) {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  width: 240px;
  padding: 4px 0;
  margin-bottom: 0;
  height: 50px;
  input {
    color: rgba(0, 0, 0, 0.88);
  }
  input::placeholder {
    color: map-get($map: $grey-color, $key: grey-550);
  }
  :global(.ant-picker-suffix) {
    svg {
      color: map-get($map: $grey-color, $key: grey-550);
    }
  }
}
:global(.pageLayout) {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 946px;
}
:global(.maxWidth946) {
  max-width: 946px;
}
:global(.br-8) {
  border-radius: map-get($map: $scale, $key: 8) !important;
}
:global(.width-800) {
  width: 800px;
}